import * as React from 'react';
import { format } from 'date-fns';
import cn from 'classnames';

import { DEFAULT_DATE_FORMAT, DayType, BgColors } from '../constants';

interface Props {
  isSelected: boolean;
  dayType: DayType;
  date: Date;
  activeModifiers?: {
    outside?: boolean;
    disabled?: boolean;
    selected?: boolean;
    freeShipping?: boolean;
    critical?: boolean;
    standard?: boolean;
  };
}

const dayTypeToClassName = (dayType: DayType | null): string => {
  switch (dayType) {
    case DayType.FREE_SHIPPING:
      return '!bg-green-500 !border-green-50 !border-4 !text-white hover:!text-white hover:!bg-green-500';
    case DayType.CRITICAL:
      return '!bg-orange-700 !border-orange-50 !border-4 !text-white hover:!text-white hover:!bg-orange-700';
    case DayType.STANDARD:
      return '!bg-blue-500 !border-blue-50 !border-4 !text-white hover:!text-white hover:!bg-blue-500';
    default:
      return '';
  }
};

const DateTime = (props: Props) => {
  const { date, isSelected, dayType, activeModifiers, ...otherProps } = props;
  const dateTime = format(date, DEFAULT_DATE_FORMAT);
  const selectedDayTypeClass = dayTypeToClassName(dayType);

  return (
    <button
      type="button"
      {...otherProps}
      className={cn(
        'rounded-full w-10 h-10 hover:bg-blue-50 font-normal border-solid border-transparent',
        'disabled:opacity-50 ',
        isSelected ? selectedDayTypeClass : '',
        {
          'rounded-full !bg-blue-500 !border-blue-50 !border-4 !text-white': isSelected,
          'hover:!text-white hover:!bg-blue-500 modifiedclass !border-2 !border-blue-50': isSelected,
          '!pointer-events-none !text-gray-700 !bg-transparent hover:!bg-transparent':
            activeModifiers.disabled,
          'cursor-pointer': !activeModifiers.disabled,
          'modifiedclass hover:bg-green-50': activeModifiers.freeShipping,
          'modifiedclass hover:bg-orange-50': activeModifiers.critical,
          'modifiedclass hover:bg-blue-50': activeModifiers.standard,
        },
      )}
    >
      <time
        dateTime={dateTime}
        className={cn('group relative flex h-full w-full flex-col items-center justify-center')}
      >
        <span>{date.getDate()}</span>
        {!isSelected && !activeModifiers?.disabled && !activeModifiers?.outside && (
          <div
            className={cn(
              'visible absolute bottom-[2px] h-[6px] w-[6px] rounded-full group-hover:invisible',
              BgColors[dayType],
            )}
          >
            {' '}
          </div>
        )}
      </time>
    </button>
  );
};

export default DateTime;
