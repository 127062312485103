import * as React from 'react';
import isEqual from 'lodash-es/isEqual';
import { Order, Shipment } from 'api/orders/types';
import SimplyShipContext from './SimplyShipContext';
import { Status } from 'libs/utils/api/types';
import { format, setHours, setMinutes } from 'date-fns';
import {
  doUpdateOrderForNewArrivalRequest,
  UpdateOrderForNewArrivalRequestSignature,
  NewArrival,
  doUpdateDatePickerRequest,
  UpdateOrderForNewArrivalResponse,
  defaultDeliveryTime,
} from 'api/simplyShip';
import { AvailableDates } from '../OrderContext/types';
import { getSameOrNextClosestDate } from '../OrderContext/utils';
import { KeyVal } from 'libs/utils/common-types';
import { setStatus, updateProductionDays, updateShipment, updateTotals } from '../OrderContext/actions';
import AppContext from 'contexts/AppContext/AppContext';
import { OrderActions } from '../OrderContext/reducer';
import { Address } from 'bundles/App/pages/Account/Addresses/types';

interface Props {
  admin: boolean;
  save: boolean;
  order: Order;
  shipment?: Shipment;
  dispatch: React.Dispatch<OrderActions>;
  setOrderTotals?: (value: UpdateOrderForNewArrivalResponse) => void;
  cartPage?: boolean;
  children: React.ReactNode;
}

const SimplyShipContextContainer: React.FunctionComponent<Props> = ({ ...props }) => {
  const appContext = React.useContext(AppContext);
  const [triggerNewArrival, setTriggerNewArrival] = React.useState<boolean>(false);
  const [availableDates, setAvailableDates] = React.useState<AvailableDates>(null);
  const [availableTimes, setAvailableTimes] = React.useState<string[]>(null);
  const { order, dispatch } = props;

  const createNewArrival = () => {
    const shipment = props.shipment || order.shipment;

    const readyAtDate = new Date(appContext.keepTimezoneConvertTime(order.readyAt));

    if (props.cartPage || !shipment?.deliveryDate) {
      return {
        address: null,
        readyAtDate: props.admin ? readyAtDate : null,
        deliveryDate: null,
        selectedTime: defaultDeliveryTime,
        freeShipping: false,
      } as NewArrival;
    }

    let deliveryDate = new Date(appContext.keepTimezoneConvertTime(shipment.deliveryDate));

    const selectedTime = order.shipment ? format(deliveryDate, 'h:mma') : null;
    deliveryDate = setMinutes(setHours(deliveryDate, 0), 0);

    return {
      address: shipment.address,
      readyAtDate: props.admin ? readyAtDate : null,
      deliveryDate: order.shipment ? deliveryDate : null,
      selectedTime,
      freeShipping: false,
    } as NewArrival;
  };

  const [initNewArrival, setInitNewArrival] = React.useState<NewArrival>(createNewArrival());
  const [newArrival, setNewArrival] = React.useState<NewArrival>(initNewArrival);
  const [datesChanged, setDatesChanged] = React.useState<KeyVal | null>(null);

  const newArrivalValid = () => newArrival.address && order.lineItems.length > 0;

  const updateDatePickerData = () => {
    setStatus(dispatch, 'loading');
    setAvailableDates(null);
    setAvailableTimes(null);

    doUpdateDatePickerRequest(newArrival.address, props.admin, order.number, newArrival.readyAtDate).then(
      res => {
        if (res.status === Status.Ok) {
          const { freeShippingDates, rushCriticalDates, normalDates, minDate, maxDate } = res.payload;

          const newAvailableDates = { freeShippingDates, rushCriticalDates, normalDates, minDate, maxDate };
          const areDatesTheSame = JSON.stringify(availableDates) === JSON.stringify(newAvailableDates);
          setAvailableDates(newAvailableDates);
          if (areDatesTheSame) {
            setStatus(dispatch, 'loaded');
          }
        } else if (res.status === Status.ClientError) {
          setStatus(dispatch, { message: res.payload.message });
        } else {
          setStatus(dispatch, { message: 'Failed to populate calendar' });
        }
      },
    );
  };

  const isFreeShipping = React.useCallback(
    (date: Date) =>
      !!availableDates &&
      !!availableDates.freeShippingDates &&
      availableDates.freeShippingDates.includes(format(date, 'MM/dd/yyyy')),
    [availableDates],
  );

  const onDateChange = React.useCallback(
    (deliveryDate: Date) => {
      setNewArrival({ ...newArrival, deliveryDate, freeShipping: isFreeShipping(deliveryDate) });
      setTriggerNewArrival(true);
    },
    [dispatch, isFreeShipping, newArrival, order],
  );

  const doNewArrivalRequest: UpdateOrderForNewArrivalRequestSignature = React.useCallback(
    (admin: boolean, save: boolean, orderNumber: string, newArrivalParam: NewArrival) =>
      new Promise(resolve => {
        setStatus(dispatch, 'loading');

        doUpdateOrderForNewArrivalRequest(admin, save, orderNumber, newArrivalParam).then(res => {
          if (res.status === Status.Ok) {
            if (typeof props.setOrderTotals !== 'undefined') {
              props.setOrderTotals(
                res.payload.updateOrderForNewArrivalResponse as UpdateOrderForNewArrivalResponse,
              );
            }
            if (save) {
              updateShipment(dispatch, res.payload.shipment);
              updateProductionDays(dispatch, res.payload.productionDays);
              updateTotals(dispatch, res.payload.updateOrderForNewArrivalResponse);
            }
            setStatus(dispatch, 'loaded');
          } else if (res.status === Status.ClientError) {
            setStatus(dispatch, { message: res.payload.message });
          } else {
            setStatus(dispatch, { message: 'Failed to get Simply ship quote' });
          }

          resolve(res);
        });
      }),
    [dispatch],
  );

  React.useEffect(() => {
    const updateArrival = createNewArrival();

    if (!isEqual(initNewArrival, updateArrival)) {
      setInitNewArrival(updateArrival);
      setNewArrival(updateArrival);
    }
  }, [order.shipment]);

  React.useEffect(() => {
    if (newArrivalValid() && !order.shipment?.isCustomized) {
      updateDatePickerData();
    }
  }, [JSON.stringify(newArrival.address), newArrival.readyAtDate, JSON.stringify(order.lineItems)]);

  React.useEffect(() => {
    if (!!availableDates && newArrivalValid() && !order.shipment?.isCustomized) {
      const oldDeliveryDate = newArrival.deliveryDate === null ? null : newArrival.deliveryDate;
      const deliveryDate =
        newArrival.deliveryDate === null || (order.shipment && order.shipment.quoteState === 'invalid')
          ? new Date(availableDates.maxDate)
          : getSameOrNextClosestDate(newArrival.deliveryDate, availableDates);

      onDateChange(deliveryDate);

      if (!!oldDeliveryDate && format(oldDeliveryDate, 'M/d/yyyy') !== format(deliveryDate, 'M/d/yyyy')) {
        setDatesChanged({
          old: format(oldDeliveryDate, 'M/d/yyyy'),
          new: format(deliveryDate, 'M/d/yyyy'),
        });
      }
    }
  }, [availableDates]);

  React.useEffect(() => {
    if (!!availableDates && newArrivalValid() && !order.shipment?.isCustomized && triggerNewArrival) {
      setTriggerNewArrival(false);

      if (!!datesChanged && datesChanged.new !== format(newArrival.deliveryDate, 'M/d/yyyy')) {
        setDatesChanged(null);
      }

      doNewArrivalRequest(props.admin, props.save, order.number, newArrival).then(res => {
        if (res.status === Status.Ok) {
          const payload = res.payload.updateOrderForNewArrivalResponse;
          setAvailableTimes(payload.timeOfDays);
          const selectedTime = payload.timeOfDays.includes(newArrival.selectedTime)
            ? newArrival.selectedTime
            : payload.timeOfDays[payload.timeOfDays.length - 1];

          if (selectedTime !== newArrival.selectedTime) {
            setNewArrival({ ...newArrival, selectedTime });
          }
        }
      });
    }
  }, [triggerNewArrival]);

  const onAddressChange = React.useCallback(
    (address: Address) => setNewArrival({ ...newArrival, address }),
    [newArrival],
  );

  const onTimeChange = React.useCallback(
    (selectedTime: string) => {
      setNewArrival({ ...newArrival, selectedTime });
      setTriggerNewArrival(true);
    },
    [newArrival],
  );
  const onReadyAtChange = React.useCallback(
    (readyAtDate: Date) => {
      setNewArrival({ ...newArrival, readyAtDate });
    },
    [newArrival],
  );

  return (
    <SimplyShipContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        admin: props.admin,
        updateOrderForNewArrival: doNewArrivalRequest,
        initNewArrival,
        newArrival,
        onAddressChange,
        onReadyAtChange,
        onDateChange,
        onTimeChange,
        availableDates,
        availableTimes,
        datesChanged,
      }}
    >
      {props.children}
    </SimplyShipContext.Provider>
  );
};

export default SimplyShipContextContainer;
