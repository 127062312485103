import * as React from 'react';
import AbstractInput from '../Formik/AbstractInput/AbstractInput';
import { FieldInputProps } from 'formik';

interface Props {
  field?: FieldInputProps<string>;
  value: string;
  onWrapperClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled?: boolean;
}

const InputWrapper = ({ field, value, disabled, placeholder, ...otherProps }: Props) => (
  <AbstractInput
    {...field}
    disabled={disabled}
    component="datepicker"
    value={value || placeholder}
    selectIconColor="dark"
    size="md"
    onClick={otherProps.onWrapperClick}
    {...otherProps}
  />
);

export default InputWrapper;
